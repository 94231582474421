<template>
  <div>
    <div class="condition">
      <!-- 资产编号、IMEI、设备编号、控制器编号、更换人 -->
      <el-input
        class="input public"
        v-model="keyWord"
        placeholder="请输入关键词 资产编码,IMEI,设备编码"
      ></el-input>
      <el-button class="query button_query" @click="getFunctionDet"
        >查询</el-button
      >
    </div>
    <div class="table">
      <el-table
        stripe
        :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-row-class-name="tableRowClassName"
      >
        <el-table-column label="设备编码" width="120">
          <template slot-scope="scope"
            ><span @click="jumpDetails(scope.row)">
              {{ scope.row.deviceCode }}</span
            ></template
          >
        </el-table-column>
        <el-table-column prop="deviceFeature" label="设备型号" width="120">
          <template slot-scope="scope">
            {{ scope.row.deviceFeatureName }}
          </template>
        </el-table-column>
        <el-table-column prop="assetCode" label="资产编码">
          <template slot-scope="scope">
            <span @click="jumpDetails(scope.row)">{{
              scope.row.assetCode
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="URL"> </el-table-column>
        <el-table-column prop="deviceRfId" label="RFID">
          <template slot-scope="scope">
            <span @click="jumpDetails(scope.row)">{{
              scope.row.deviceRfId
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceImei" label="IMEI">
          <template slot-scope="scope">
            <span @click="jumpDetails(scope.row)">{{
              scope.row.deviceImei
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="在线状态" width="60">
          <template slot-scope="scope">{{
            scope.row.onlineStatus == 1 ? "在线" : "离线"
          }}</template>
        </el-table-column>
        <el-table-column prop="customerName" label="客户"> </el-table-column>
        <el-table-column prop="deptName" label="所属组织"> </el-table-column>
        <el-table-column prop="putArea" label="投放区域" width="60">
        </el-table-column>
        <el-table-column prop="putAreaTime" label="投放时间">
          <template slot-scope="scope">
            {{ scope.row.putAreaTime | formattingTime }}
          </template>
        </el-table-column>
        <el-table-column label="设备状态" width="60">
          <template slot-scope="scope">{{
            deviceStatus(scope.row.status)
          }}</template>
        </el-table-column>

        <el-table-column prop="product_time" label="生产时间">
          <template slot-scope="scope">
            {{ scope.row.productTime | formattingTime }}
          </template>
        </el-table-column>
        <el-table-column prop="assetYear" label="资产年限"> </el-table-column>
        <el-table-column prop="isExpire" label="保修期状态" width="70">
          <template slot-scope="scope">
            {{ scope.row.isExpire | isExpires }}
          </template>
        </el-table-column>
        <el-table-column prop="deviceType" label="资产类型"> </el-table-column>
        <el-table-column prop="deviceLocation" label="当前地址">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getHistoryEquipments } from "@/api/historyData.js";
export default {
  name: "equipmentHistory",
  data() {
    return {
      keyWord: "",
      tableData: []
    };
  },
  filters: {
    isExpires(val) {
      if (val == 1) {
        return "在保";
      } else if (val == 2) {
        return "不在保";
      }
    }
  },
  methods: {
    deviceStatus(val) {
      if (val == 1) {
        return "正常";
      } else if (val == 2) {
        return "维修";
      } else if (val == 3) {
        return "报废";
      }
    },
    //查询
    getFunctionDet() {
      let data = {
        deviceImei: this.keyWord
      };
      getHistoryEquipments(data).then(res => {
        this.tableData = res.data.data;
        console.log(this.tableData, "tableData");
      });
    },
    tableRowClassName(row, rowIndex) {
      if (rowIndex == 0) return "headerStyle";
    }
  }
};
</script>

<style scoped>
.button_query {
  margin-left: 10px;
}
.input {
  width: 246px;
  margin-right: 10px;
}
.public {
  margin-bottom: 20px;
}
.device-img {
  width: 100px;
  height: auto;
}
</style>
