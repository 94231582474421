import axios from "../utils/request";

//历史数据查询
export function getHistoryInfoListPage(data) {
  return axios({
    url: "/clodchainAM/web/get_history_info_list_page",
    method: "get",
    params: data
  });
}

export function exportData(data) {
  return axios({
    url: "/clodchainAM/web/batch_export_history_info",
    method: "get",
    params: data,
    responseType: "blob"
  });
}

//历史设备查询
export function getHistoryEquipments(data) {
  return axios({
    url: "/clodchainAM/web/get_basic_history_information",
    method: "get",
    params: data
  });
}
